<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Как начать работать над образом будущей куклы? Как его создать, на что обратить особое внимание? С какими проблемами мы встречаемся и это не только про творчество... Ваши страхи, трудности, боязнь «белого листа».</p>
            <p>Об этом всем мы поговорили на прямом эфире, выкладываю запись :)</p>           
        </div>

        <div class="container">
            <iframe class="video" src="https://www.youtube.com/embed/2xmX4A2cb2g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>

        <div class="MBtextcont">
            <h3>Следующее занятие выйдет совсем скоро! А пока: </h3>
            <ol>
                <li><b>Определитесь с образом</b> вашей девочки</li>
                <li><b>Распечатайте <a target="_blank" href="/files/DrawingBEST.pdf">чертеж в 3-ех экземплярах</a></b> (размер куклы в распечатанном виде 43-44см)</li>
                <li><b>Подговьте материалы</b> к завтрашнему занятию: карандаш, малярный скотч, ножницы или макетный нож, картон А4 или А5</li>
            </ol>
        </div>
    </KCourse>
</template>

<script>
    // import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js'

    export default {
        name: "MBCourseHead",
        components: {
            // VideoView,
            KCourse,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style scoped>
.container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>